var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.field.industrialStructParameterType === 'select_unique' ||
      _vm.field.industrialStructParameterType === 'select_multiple'
    )?_c('b-col',{staticClass:"form-group",attrs:{"md":"12"}},[_c('label',{attrs:{"for":("custom-field-" + (_vm.field.id))}},[_vm._v(" "+_vm._s(_vm.field.label)+" ")]),_c('SingleSelect',{class:_vm.field.industrialStructParameterType === 'select_multiple' ? 'custom-multiple' : '',attrs:{"id":("custom-field-" + (_vm.field.id)),"placeholder":_vm.$t('Select'),"optionSelected":_vm.verifySelectedOptions(),"options":_vm.field.industrialStructParameterOption.map(function (item) { return ({
          value: item.id,
          text: item.name
        }); }),"searchable":false,"translate":true,"state":_vm.field.required && _vm.hasError ? _vm.verifyValidFields(_vm.field) : null,"multiselect":_vm.field.industrialStructParameterType === 'select_multiple' ? true : false},on:{"input":function (value) { return _vm.alterValueInternal(value); }}},[_vm._v(" "+_vm._s(_vm.$t('RequiredField'))+" ")])],1):_vm._e(),(
      _vm.field.industrialStructParameterType === 'input_text' ||
      _vm.field.industrialStructParameterType === 'input_numeric'
    )?_c('b-col',{staticClass:"form-group input-block",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.field.label,"label-for":("custom-field-" + (_vm.field.id)),"invalid-feedback":_vm.$t('RequiredField')}},[_c('b-form-input',{attrs:{"id":("custom-field-" + (_vm.field.id)),"value":_vm.fieldValueInternal,"placeholder":_vm.$t('ResourceStatus.Placeholder'),"state":_vm.field.required && _vm.hasError ? _vm.verifyValidFields(_vm.field) : null,"type":_vm.field.industrialStructParameterType === 'input_numeric' ? 'number' : 'text'},nativeOn:{"input":function($event){return (function (e) {
            var newValue = _vm.applyMask(e.target.value);
            e.target.value = newValue;
            _vm.alterValueInternal(newValue);
          }).apply(null, arguments)}}})],1)],1):_vm._e(),(_vm.field.industrialStructParameterType === 'input_text_area')?_c('b-col',{staticClass:"form-group input-text-area",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.field.label,"label-for":("custom-field-" + (_vm.field.id)),"invalid-feedback":_vm.$t('RequiredField')}},[_c('b-form-textarea',{attrs:{"id":("custom-field-" + (_vm.field.id)),"value":_vm.fieldValueInternal,"placeholder":_vm.$t('ResourceStatus.Placeholder'),"state":_vm.field.required && _vm.hasError ? _vm.verifyValidFields(_vm.field) : null,"rows":"2","no-resize":""},on:{"input":function (value) { _vm.alterValueInternal(value)}}})],1)],1):_vm._e(),(_vm.field.industrialStructParameterType === 'switch' || _vm.field.industrialStructParameterType === 'checkbox')?_c('b-col',{class:[
      'custom-switch-button' && _vm.field.industrialStructParameterType === 'switch',
      'custom-checkbox' && _vm.field.industrialStructParameterType === 'checkbox' ],attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-checkbox',{staticClass:"check-button p-0",attrs:{"id":_vm.field.name,"name":"resource-check-button","switch":_vm.field.industrialStructParameterType === 'switch'},on:{"change":function (value) { return _vm.alterValueInternal(value); }},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}}),_c('div',{staticClass:"text-allow"},[_vm._v(_vm._s(_vm.$t(_vm.field.name)))])],1)]):_vm._e(),(_vm.field.industrialStructParameterType === 'datepicker' || _vm.field.industrialStructParameterType === 'range_dates' || _vm.field.industrialStructParameterType === 'input_hour')?_c('b-col',{staticClass:"form-group datepicker",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.field.label,"label-for":("custom-field-" + (_vm.field.id)),"invalid-feedback":_vm.$t('RequiredField')}},[_c('DateTimePicker',{ref:"dateTimePickerRef",attrs:{"datepicker-id":("" + (_vm.field.id)),"position":"bottom","range":_vm.field.industrialStructParameterType === 'range_dates',"type":_vm.field.industrialStructParameterType === 'input_hour' && 'time',"disabled":false,"weekDays":_vm.setWeekDays(),"month-names":_vm.setMonths(),"block-dates":_vm.blockDates,"txt-cancel-button":_vm.$t('Datepicker.Cancel'),"txt-save-button":_vm.$t('Datepicker.Save'),"txt-time":_vm.$t('Datepicker.Time'),"txt-date":_vm.$t('Datepicker.Date'),"maxRangeDates":_vm.maxRangeDates},on:{"input":function (value) { return _vm.handleDatepickerChange(value); }},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"input-with-icon"},[(_vm.field.industrialStructParameterType === 'input_hour')?_c('TimeIcon',{staticClass:"icon-input"}):_vm._e(),(_vm.field.industrialStructParameterType === 'datepicker')?_c('CalendarIcon',{staticClass:"icon-input"}):_vm._e(),_c('b-form-input',{class:[
                  _vm.field.industrialStructParameterType !== 'range_dates' && 'input-datepicker'
                ],attrs:{"id":("custom-field-" + (_vm.field.id)),"value":_vm.inputDatepicker,"type":"text","placeholder":_vm.field.industrialStructParameterType === 'range_dates' 
                  ? ("00-00-0000 " + (_vm.$t('Operator.Until')) + " 00-00-0000") 
                  :_vm.field.industrialStructParameterType === 'datepicker' ? '00-00-0000' 
                  : '00:00',"onkeydown":"return false;"}})],1)]},proxy:true}],null,false,2827107604),model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }